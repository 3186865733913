import {NotificationListItem} from '@/types/NotificationListItem'

function createMockEvent(index: number): NotificationListItem {
  const types = ['Event', 'Condition']
  const statuses = ['ACTIVE', 'TRIGGERED']
  const baseName = `Mock Item ${index}`
  const baseEvent = `Event ${index}`
  const basePublishedOn = new Date(`2024-06-${index < 10 ? '0' + index : index}T12:00:00.000+00:00`)

  return {
    id: index.toString(),
    name: baseName,
    type: types[index % 2],
    event: `${baseEvent} ${index % 2 === 0 ? '==' : '!='} Value ${index}`,
    status: statuses[index % 2],
    publishedOn: basePublishedOn.toISOString(),
  }
}

const mockDataSize = 16
export const mockData: NotificationListItem[] = Array.from({length: mockDataSize}, (_, index) =>
  createMockEvent(index + 1)
)
