import {z} from 'zod'
import {NodeTypeEnum} from '../NodeTypeEnum'
export type NodeTypeForParamsFiltersEnum = NodeTypeEnum.entryCondition | NodeTypeEnum.variant

type ParamConditional = 'AND' | 'OR' | null

export const BooleanOperatorsSchema = z.enum(['isYes', 'isNo'])
export const ComparativeOperatorsSchema = z.enum(['equalTo', 'notEqualTo'])
export const IntegerOperatorsSchema = z.enum([
  'lessThan',
  'lessOrEqual',
  'greaterOrEqual',
  'greaterThan',
  ...ComparativeOperatorsSchema.options,
])
export const ListOperatorsSchema = z.enum(['in', 'notIn'])
export const StringOperatorsSchema = z.enum([...ComparativeOperatorsSchema.options, ...ListOperatorsSchema.options])
export const DateOperatorsSchema = IntegerOperatorsSchema
export const EnumOperatorsSchema = z.enum([...ComparativeOperatorsSchema.options, ...ListOperatorsSchema.options])

export const OperatorsSchema = z.enum([
  ...BooleanOperatorsSchema.options,
  ...IntegerOperatorsSchema.options,
  ...StringOperatorsSchema.options,
  ...DateOperatorsSchema.options,
  ...EnumOperatorsSchema.options,
])
export type Operators = z.infer<typeof OperatorsSchema>

export const BooleanParamsSchema = z.enum(['activeCurrentMonthYesNo', 'activeL3M', 'registeredStoreYesNo'])
export const IntegerParamsSchema = z.enum(['daysSinceLastLogin', 'daysSinceRegistration'])
export const StringParamsSchema = z.enum([
  'storeId',
  'district',
  'state',
  'territory',
  'city',
  'salesOfficeCode',
  'salesChannelCode',
  'divisionCode',
  // WIP: Not ready on backeend yet
  'assignedSegments',
])
export const DateParamsSchema = z.enum(['registeredDate'])
export const EnumParamsSchema = z.enum(['portalTypeValue', 'storeStatusName'])

export type BooleanParams = z.infer<typeof BooleanParamsSchema>
export type IntegerParams = z.infer<typeof IntegerParamsSchema>
export type StringParams = z.infer<typeof StringParamsSchema>
export type DateParams = z.infer<typeof DateParamsSchema>
export type EnumParams = z.infer<typeof EnumParamsSchema>

export const DataParamsSchema = z.union([
  BooleanParamsSchema,
  IntegerParamsSchema,
  StringParamsSchema,
  DateParamsSchema,
  EnumParamsSchema,
])

type TypeParams = BooleanParams | IntegerParams | StringParams | DateParams | EnumParams
export type DataParams = z.infer<typeof DataParamsSchema> extends TypeParams ? TypeParams : never

export const OperatorTypeSchema = z.enum(['boolean', 'integer', 'string', 'date', 'enum'])
export type OperatorsType = z.infer<typeof OperatorTypeSchema>

export type ParamsSelector = {
  id: number
  conditional: ParamConditional
} & (
  | {
      selectedParam: ''
      selectedParamType: ''
      selectedParamData: {
        operator: Operators
        value: null
      }
    }
  | {
      selectedParam: IntegerParams
      selectedParamType: typeof OperatorTypeSchema.Values.integer
      selectedParamData: {
        operator: z.infer<typeof IntegerOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedParam: BooleanParams
      selectedParamType: typeof OperatorTypeSchema.Values.boolean
      selectedParamData: {
        operator: z.infer<typeof BooleanOperatorsSchema>
        value: null
      }
    }
  | {
      selectedParam: StringParams
      selectedParamType: typeof OperatorTypeSchema.Values.string
      selectedParamData: {
        operator: z.infer<typeof StringOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedParam: DateParams
      selectedParamType: typeof OperatorTypeSchema.Values.date
      selectedParamData: {
        operator: z.infer<typeof DateOperatorsSchema>
        value: string | null
      }
    }
  | {
      selectedParam: EnumParams
      selectedParamType: typeof OperatorTypeSchema.Values.enum
      selectedParamData: {
        operator: z.infer<typeof EnumOperatorsSchema>
        value: string | null
      }
    }
)

export type ParamFilters = {
  id: string
  type: NodeTypeForParamsFiltersEnum
  reentryDays: number | null
  haveWarnings?: boolean
  paramsSelectors: Array<ParamsSelector>
}
