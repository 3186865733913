import {cn} from '@/helpers/shadcn/utils'
import {JourneyStatus} from '@/types/JourneyStatus'
import {cva} from 'class-variance-authority'
import {Badge} from 'pepsico-ds'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

type JourneyStatusBadgeProps = {
  status: JourneyStatus
}

export const JourneyStatusBadge = ({status}: JourneyStatusBadgeProps) => {
  const {formatMessage} = useIntl()
  const label = formatMessage({id: `STATUS.${status}`})

  const variants = cva('', {
    variants: {
      status: {
        [`${JourneyStatus.new}`]: 'bg-status-NEW',
        [`${JourneyStatus.draft}`]: 'bg-status-DRAFT',
        [`${JourneyStatus.saved}`]: 'bg-status-SAVED',
        [`${JourneyStatus.published}`]: `bg-white border border-status-PUBLISHED`,
        [`${JourneyStatus.completed}`]: `bg-white border border-status-COMPLETED`,
        [`${JourneyStatus.deleted}`]: 'bg-status-DELETED',
      },
    },
  })

  const normalizedStatus = useMemo(() => {
    switch (status) {
      case JourneyStatus.new:
      case JourneyStatus.draft:
      case JourneyStatus.saved:
        return JourneyStatus.draft
      default:
        return status
    }
  }, [status])

  return (
    <Badge
      data-testid={`journey-status-${normalizedStatus}-badge`}
      data-cy={`journey-status-${normalizedStatus}-badge`}
      color="transparent"
      className={cn(variants({status}))}
      size="large"
      text={label}
      isCopyable={false}
      isRemovable={false}
    />
  )
}
