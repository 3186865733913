import {mockData} from '@/__fixtures__/events'
import {Drawer} from '@/components/Drawer/Drawer'
import EventNotificationDetails from '@/components/Drawer/EventNotificationDetails'
import {getNotificationTableColumns} from '@/components/EventListTable/components/NotificationTableColumn'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {useCallback, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {DataTable} from '../../shared-components/DataTable'

export default function EventListTable() {
  const {formatMessage} = useIntl()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const {pageIndex, pageSize} = pagination
  const [sorting, setSorting] = useState<SortingState>([])
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState<boolean>(false)
  const [, setSelectedNotificationId] = useState<string>('')

  const onPaginationDidChange = useCallback(({pageIndex, pageSize}: PaginationState) => {
    setPagination({pageIndex, pageSize})
  }, [])

  const onSortingDidChange = useCallback(
    (sorting: SortingState) => {
      setSorting(sorting)
    },
    [setSorting]
  )

  const sortedData = useMemo(() => {
    if (sorting.length === 0) {
      return mockData
    }

    const {id, desc} = sorting[0]

    const sortedData = [...mockData].sort((a, b) => {
      const aValue = a[id as keyof typeof a]
      const bValue = b[id as keyof typeof b]

      if (aValue < bValue) return desc ? 1 : -1
      if (aValue > bValue) return desc ? -1 : 1
      return 0
    })

    return sortedData
  }, [sorting])

  const paginatedData = useMemo(() => {
    const startIndex = pageIndex * pageSize
    const endIndex = startIndex + pageSize
    return sortedData.slice(startIndex, endIndex)
  }, [sortedData, pageIndex, pageSize])

  return (
    <>
      <DataTable
        columns={getNotificationTableColumns(formatMessage, setIsEventDetailsOpen, setSelectedNotificationId)}
        data={paginatedData}
        pagination={pagination}
        pageCount={Math.ceil(sortedData.length / pageSize)}
        onPaginationDidChange={onPaginationDidChange}
        sortingColumn={sorting}
        onSortingDidChange={onSortingDidChange}
        data-testid="realTime-content"
      />
      <Drawer
        headerTitle="Test Header"
        isOpen={isEventDetailsOpen}
        onClose={() => {
          setIsEventDetailsOpen(false)
        }}
        direction="right"
      >
        <EventNotificationDetails />
      </Drawer>
    </>
  )
}
