import {NodeTypeEnum} from '@/types/NodeTypeEnum'

export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME: '/',
  LIST_JOURNEYS: '/journeys',
  SUCCESSFUL_LOGIN: '/success',
  CREATE_JOURNEY: '/create',
  EDIT_JOURNEY: '/journey/:id',
  REPORTS: '/reports',
  SETTINGS: '/settings',
  EVENTS: '/events',
  ERROR: '/error',
  ERROR_403: '/error/403',
  ERROR_500: '/error/500',
}

export const ENDPOINTS = {
  LOAD_JOURNEYS: '/journeys',
  EDIT_JOURNEY: '/journeys/:id',
  SAVE_JOURNEY: '/journeys',
  PUBLISH_JOURNEY: '/journeys/:id/publish',
  JOURNEY_STATUS: '/journeys/:id/status',
  EXPORT_JOURNEY_STATUS_REPORT: '/journeys/:id/export/executions',
  EXPORT_JOURNEY_STATUS_REPORT_DATA: '/journeys/:id/export/executions/data',
  EXPORT_REQUIRES_INTERVENTION: '/journeys/:id/export/requires-intervention',
  EXPORT_REQUIRES_INTERVENTION_DATA: '/journeys/:id/export/requires-intervention/data',
  SETTINGS: '/settings',
  LOAD_STEP_CONFIG: (type: NodeTypeEnum.sendPush | NodeTypeEnum.sendWhatsApp) => {
    switch (type) {
      case NodeTypeEnum.sendPush:
        return '/step/config/push-notification'
      case NodeTypeEnum.sendWhatsApp:
        return '/step/config/whatsapp'
      default:
        throw new Error(`Unsupported step type: ${type}`)
    }
  },
  LOAD_TEMPLATES: '/message-templates/:notificationType',
  LOAD_USER_ROLES: '{{adminportal}}/v1/users/auth/cct',
}

export const DEFAULT_INTL = 'en-US'

export const STEP_CONFIG = {
  DATA_QUERY_KEY: 'stepConfig',
  DEFAULT_NODE_Y_DISTANCE: 60,
  DEFAULT_ZOOM_LEVEL: 100,
  DEFAULT_OFFSET_POSITION: {
    x: 0,
    y: 0,
  },
  DEFAULT_ENTRY_CONDITION_POSITION: {
    x: 300,
    y: 50,
  },
}

export const JOURNEY_QUERY_KEY = 'journey'
export const JOURNEY_STATUS_EXECUTION_QUERY_KEY = 'journeyStatus'

export const FEEDBACK_DURATION = 5 * 1000 // 5 seconds
export const EXECUTION_STATUS_POOLING_INTERVAL = 15 * 1000 // 15 seconds

export const OKTA_AUTH_CONFIG_KEY = 'okta-auth-config'
export const OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage'
export const OKTA_FORBIDDEN_ERROR = 'User is not assigned to the client application.'

export const timezones = {
  UTC: 'UTC',
  AR: 'America/Argentina/Buenos_Aires',
  BR: 'America/Sao_Paulo',
  CL: 'America/Santiago',
  MX: 'America/Mexico_City',
  PL: 'Poland',
  TR: 'Turkey',
  US: 'America/Los_Angeles',
  XX: 'SUT Timezone',
} as const

export type Timezone = (typeof timezones)[keyof typeof timezones]
