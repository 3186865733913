import {canvasApp} from '@/components/Canvas/Application/Application'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useAppDispatch} from '@/store'
import {RecurrenceDays} from '@/types/Day'
import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {Recurrence} from '@/types/Recurrence'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {JourneyConfigEditableFields, setJourneyMeta, setPeriod, setRecurrence, updateReEntryDays} from '@ReduxActions'
import _ from 'lodash'
import {ChangeEvent, useCallback, useMemo, useState} from 'react'

export const useJourneyConfigEditor = () => {
  const dispatch = useAppDispatch()
  const {...savedConfig} = _.omit(useJourneyMetaConfig(), ['status', 'isNew', 'id'])
  const [temporaryConfig, setTemporaryConfig] = useState<JourneyConfigEditableFields>(savedConfig)

  const hasUnsavedChanges = useMemo(() => !_.isEqual(temporaryConfig, savedConfig), [savedConfig, temporaryConfig])
  const isAllRequiredFilled = useMemo(() => {
    const isJourneyTypeDefined = isFeatureEnabled(FeatureToggle.journeyType) ? !!temporaryConfig.type : true

    return (
      !!temporaryConfig.name &&
      !!temporaryConfig.country &&
      isJourneyTypeDefined &&
      !temporaryConfig.timeExecutionWindows?.some(window => !window.start)
    )
  }, [temporaryConfig.country, temporaryConfig.type, temporaryConfig.name, temporaryConfig.timeExecutionWindows])

  const onNameChanged = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, name: evt.target.value}))
  }, [])

  const onDescriptionChanged = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, description: evt.target.value}))
  }, [])

  const onCountryChanged = useCallback((value: string) => {
    setTemporaryConfig(prevConfig => ({...prevConfig, country: value}))
  }, [])

  const onPeriodDidChange = useCallback((start?: string, end?: string) => {
    const period = start || end ? {start, end} : undefined
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      period,
    }))
  }, [])

  const onJourneyTypeDidChange = useCallback((value: JourneyType) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      type: value,
    }))
  }, [])

  const onRecurrenceDidChange = useCallback((value?: Recurrence) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      recurrence: value,
    }))
  }, [])

  const onRecurrenceDaysDidChange = useCallback((value: RecurrenceDays) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      recurrenceDays: value,
    }))
  }, [])

  const onTimeExecutionsDidChange = useCallback((value?: TimeExecutionWindows) => {
    setTemporaryConfig(prevConfig => ({
      ...prevConfig,
      timeExecutionWindows: value,
    }))
  }, [])

  const onJourneyTypeDispatch = useCallback(() => {
    if (temporaryConfig.type !== JourneyTypeSchema.enum.ONE_TIME) return
    const {first} = canvasApp.getNodes()
    dispatch(setPeriod({start: temporaryConfig.period?.start, end: undefined}))
    dispatch(setRecurrence(undefined))
    dispatch(updateReEntryDays({id: first.getID(), reentryDays: null}))
  }, [temporaryConfig.type, temporaryConfig.period, dispatch])

  const saveTemporaryChanges = useCallback(
    (onDidSave?: () => void) => {
      dispatch(
        setJourneyMeta({
          ...temporaryConfig,
        })
      )
      dispatch(setPeriod({start: temporaryConfig.period?.start, end: temporaryConfig.period?.end}))
      onJourneyTypeDispatch()
      onDidSave?.()
    },
    [dispatch, onJourneyTypeDispatch, temporaryConfig]
  )

  return {
    onNameChanged,
    onDescriptionChanged,
    onCountryChanged,
    onPeriodDidChange,
    onRecurrenceDidChange,
    onJourneyTypeDidChange,
    onTimeExecutionsDidChange,
    savedConfig,
    temporaryConfig,
    saveTemporaryChanges,
    hasUnsavedChanges,
    isAllRequiredFilled,
    onRecurrenceDaysDidChange,
  }
}
