import {ROUTES} from '@/config/constants'
import {useOktaStorage} from '@/hooks/useOktaStorage'
import {Avatar, Icon} from 'pepsico-ds'
import {PropsWithChildren} from 'react'
import {useNavigate} from 'react-router-dom'

type HeaderProps = PropsWithChildren & {
  logo: string
  title: string
}

export const Header = ({logo, title, ...props}: HeaderProps) => {
  return (
    <header
      data-testid="header"
      className="min-w-screen flex h-16 items-center justify-between bg-neutral-800 px-6 text-white"
    >
      <div data-testid="header-logo" className="flex h-auto flex-1">
        <img src={logo} alt="Pepsico logo" className="w-28" />
      </div>
      <div data-testid="header-title" className="flex flex-1 items-center gap-2 text-base font-bold text-zinc-50">
        <Icon icon="mediation" style={{fontSize: '1rem'}} /> {title}
      </div>
      <div data-testid="header-user" className="flex w-auto items-center justify-end">
        {props.children && (
          <div data-testid="header-extra-icons" className="mr-4 flex flex-1 items-center">
            {props.children}
          </div>
        )}

        <UserAvatar />
      </div>
    </header>
  )
}

const UserAvatar = () => {
  const {
    claims: {FirstName, LastName},
  } = useOktaStorage()
  const navigate = useNavigate()

  const fullName = !!FirstName && !!LastName ? `${FirstName} ${LastName}` : ''

  return (
    <span data-testid="header-user-avatar">
      <Avatar type="name" name={fullName} className="h-8 w-8" onClick={() => navigate(ROUTES.LOGOUT)} />
    </span>
  )
}
