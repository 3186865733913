import {FeatureToggle} from '@/config/featureToggles'
import {z} from 'zod'

// Can be used to enable features in development
export const isDev = () => {
  return import.meta.env.MODE === 'development'
}

// Can be used to enable different behaviors on code during development for debuging purpose
// Activated via query-string (?debug=true)
export const isDebug = () => {
  const hasDebugParam = new URLSearchParams(window.location.search).get('debug') === 'true'
  return isDev() && hasDebugParam
}

// Returns true when running the System Under Test
// Can be used to disable features and change behaviors
export const isSUT = () => {
  return ['test', 'sut', 'e2e'].includes(import.meta.env.MODE)
}

// MARK: - Env variables validator

const EnvRequired = z.string()

const EnvFeatureToggle = z
  .string()
  .optional()
  .transform((value, ctx) => {
    if (!isSUT() && isDev() && [undefined, null, ''].includes(value)) {
      console.warn(`[FeatureToggle] ${ctx.path} not set, will assume "Disabled"`)
    }

    return !!value
  })

z.object({
  // Required env variables for the app to work
  VITE_API_URL: EnvRequired,
  VITE_API_ADMIN_URL: EnvRequired,
  VITE_COUNTRIES: EnvRequired,
  VITE_OKTA_ISSUER: EnvRequired,
  VITE_OKTA_CLIENTID: EnvRequired,
  VITE_OKTA_GLOBAL_ENABLED: EnvFeatureToggle,

  // Feature toggles (optional env variables, will assume `false` when not set)
  [FeatureToggle.countryHeader]: EnvFeatureToggle,
  [FeatureToggle.customSettings]: EnvFeatureToggle,
  [FeatureToggle.dataReports]: EnvFeatureToggle,
  [FeatureToggle.emojiPicker]: EnvFeatureToggle,
  [FeatureToggle.journeyType]: EnvFeatureToggle,
  [FeatureToggle.recurrence]: EnvFeatureToggle,
  [FeatureToggle.recurrenceDaySelector]: EnvFeatureToggle,
  [FeatureToggle.scheduleDateRange]: EnvFeatureToggle,
  [FeatureToggle.sendPushCustomImage]: EnvFeatureToggle,
  [FeatureToggle.sendPushCustomLandingPage]: EnvFeatureToggle,
  [FeatureToggle.timeExecutionWindow]: EnvFeatureToggle,
  [FeatureToggle.userRole]: EnvFeatureToggle,
  [FeatureToggle.triggerBasedNotification]: EnvFeatureToggle,

  // Steps
  [FeatureToggle.stepRequiresIntervention]: EnvFeatureToggle,
  [FeatureToggle.stepSendPush]: EnvFeatureToggle,
  [FeatureToggle.stepSendSms]: EnvFeatureToggle,
  [FeatureToggle.stepSendWhatsApp]: EnvFeatureToggle,

  // WIP
  [FeatureToggle.editPublished]: EnvFeatureToggle,
  [FeatureToggle.sendPushLanguageButtons]: EnvFeatureToggle,
}).parse(import.meta.env)
