import {OKTA_AUTH_CONFIG_KEY, OKTA_TOKEN_STORAGE_KEY, ROUTES} from '@/config/constants'
import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import OktaAuth, {AuthState} from '@okta/okta-auth-js'

export const oktaAuthConfig = () => {
  const config = {
    issuer: import.meta.env.VITE_OKTA_ISSUER,
    clientId: import.meta.env.VITE_OKTA_CLIENTID,
    redirectUri: `${window.location.origin}${
      isFeatureEnabled(FeatureToggle.isOktaGlobal) ? ROUTES.LOGIN : ROUTES.SUCCESSFUL_LOGIN
    }`,
    postLogoutRedirectUri: `${window.location.origin}${
      isFeatureEnabled(FeatureToggle.isOktaGlobal) ? ROUTES.LOGOUT : ROUTES.LOGIN
    }`,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    tokenManager: {
      expireEarlySeconds: 60,
      // expireEarlySeconds: 3595, // Use it during development to debug faster token expiration delay
      autoRenew: true,
    },
    pkce: true,
  }

  sessionStorage.setItem(OKTA_AUTH_CONFIG_KEY, JSON.stringify(config))
  return config
}

export const oktaAuth = new OktaAuth(oktaAuthConfig())

export const oktaAccessToken = () => {
  try {
    const tokenStorage = localStorage.getItem(OKTA_TOKEN_STORAGE_KEY)
    const authStateStorage = JSON.parse(`${tokenStorage}`) as AuthState
    const accessToken = authStateStorage?.accessToken?.accessToken

    return `Bearer ${accessToken}`
  } catch {
    return ''
  }
}
