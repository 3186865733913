import {setInitialNodeParamFiltersData} from '@ReduxActions'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {store} from '../../../../store'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeModel} from '../CustomNodeModel'
import {EntryConditionPortModel} from './EntryConditionPortModel'

// this can be further extended for more complicated node types
export class EntryConditionNodeModel extends CustomNodeModel {
  constructor(id?: string) {
    const obj = {
      type: NodeTypeEnum.entryCondition,
      id,
      extras: {
        name: 'Entry Condition',
        priority: 1,
        nodeSize: 200,
      },
    }
    if (!id) delete obj.id

    super(obj)

    this.addPort(new EntryConditionPortModel(PortModelAlignment.BOTTOM))
    this.setLocked(true)

    store.dispatch(
      setInitialNodeParamFiltersData({
        id: this.getID(),
        type: NodeTypeEnum.entryCondition,
      })
    )
  }
}
