import {cn} from '@/helpers/shadcn/utils'
import {Button, IButtonProps} from 'pepsico-ds'
import {useIntl} from 'react-intl'
import {Spinner} from '../Spinner'

export type ButtonBaseProps = IButtonProps & {
  isLoading?: boolean
  isLoadingText?: string
}

const ButtonBase = ({
  size = 'medium',
  isLoading,
  isLoadingText,
  disabled,
  className,
  children,
  ...props
}: ButtonBaseProps) => {
  const {formatMessage} = useIntl()

  return (
    <Button
      {...props}
      className={cn('whitespace-nowrap', className)}
      variant={isLoading ? 'borderless' : props.variant}
      size={size}
      disabled={disabled || isLoading}
      iconLeading={isLoading ? undefined : props.iconLeading}
      iconTrailing={isLoading ? undefined : props.iconTrailing}
    >
      {isLoading && (
        <>
          <Spinner size={size} className="-ml-1 mr-2" />
          {isLoadingText ?? formatMessage({id: 'GENERAL.LOADING'})}
        </>
      )}
      {!isLoading && children}
    </Button>
  )
}

export const PrimaryButton = (props: ButtonBaseProps) => {
  return <ButtonBase {...props} variant="primary" />
}

export const SecondaryButton = (props: ButtonBaseProps) => {
  return <ButtonBase {...props} variant="secondary" />
}

export const BorderlessButton = (props: ButtonBaseProps) => {
  return <ButtonBase {...props} variant="borderless" />
}
