import {NotificationNameCell} from '@/components/EventListTable/components/NotificationCustomizeCell'
import {NotificationListItem} from '@/types/NotificationListItem'
import {ColumnDef, Row} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'
import {DatetimeCell, JourneyDescriptionCell} from '../../JourneysListTable/components/CustomCells'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getNotificationTableColumns = (
  formatMessage: IntlFormatters['formatMessage'],
  setIsEventDetailsOpen: (value: boolean | ((prevValue: boolean) => boolean)) => void,
  setSelectedNotificationId: (value: string | ((prevValue: string) => string)) => void
): ColumnDef<NotificationListItem, CustomColumnMeta>[] => {
  function openDrawer(row: Row<NotificationListItem>) {
    // trigger API call for notification details row.original.id.
    setSelectedNotificationId(row.original.id)
    setIsEventDetailsOpen(true)
  }
  return [
    {
      accessorKey: 'name',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_ID_COLUMN'})} />
      ),
      cell: ({row}) => (
        <NotificationNameCell
          name={row.getValue('name')}
          onClick={() => {
            openDrawer(row)
          }}
        />
      ),
      meta: {
        styles: {
          minWidth: 300,
          width: 300,
        },
      },
    },
    {
      accessorKey: 'type',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_TYPE_COLUMN'})} />
      ),
      enableSorting: false,
      cell: ({row}) => <JourneyDescriptionCell description={row.getValue('type')} />,
      meta: {
        styles: {
          minWidth: 100,
          width: 100,
        },
      },
    },
    {
      accessorKey: 'event',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_EVENT_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 300,
          width: 300,
        },
      },
      cell: ({row}) => <JourneyDescriptionCell description={row.getValue('event')} />,
    },
    {
      accessorKey: 'status',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_STATUS_COLUMN'})} />
      ),
      cell: ({row}) => <JourneyDescriptionCell description={row.getValue('status')} />,
      meta: {
        styles: {
          minWidth: 80,
        },
      },
      sortDescFirst: false,
    },
    {
      accessorKey: 'publishedOn',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_PUBLISHED_COLUMN'})} />
      ),
      cell: ({row}) => <DatetimeCell value={row.getValue('publishedOn')} />,
      meta: {
        styles: {
          minWidth: 170,
        },
      },
      sortDescFirst: false,
    },
  ]
}
