import CreateNotificationPage from '@/components/Drawer/CreateNotificationPage'
import {Drawer} from '@/components/Drawer/Drawer'
import NotificationDetailsPreview from '@/components/Drawer/NotificationDetailsPreview'
import {NotificationDrawerStatusEnum} from '@/types/NotificationDrawerStatusEnum'
import {Period} from '@/types/Period'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean | ((prevValue: boolean) => boolean)) => void
}

export default function NotificationDrawer({isOpen, setIsOpen}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  const title = formatMessage({id: 'NOTIFICATION_BUILDER.CREATE_NOTIFICATION_HEADER'})
  const [drawerStatus, setDrawerStatus] = useState<NotificationDrawerStatusEnum>(
    NotificationDrawerStatusEnum.createNotification
  )
  const [notificationName, setNotificationName] = useState<string>('')
  const [period, setPeriod] = useState<Period>({start: '', end: ''})
  const [timeExecutionWindow, setTimeExecutionWindow] = useState<TimeExecutionWindowNotification>({start: '', end: ''})
  const [fallsOnBankHoliday, setFallsOnBankHoliday] = useState<string>('')

  const [notificationType, setNotificationType] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const cleanUpAllFields = function (): void {
    setNotificationName('')
    setNotificationType('')
    setPeriod({start: '', end: ''})
    setTimeExecutionWindow({start: '', end: ''})
    setFallsOnBankHoliday('')
    setSubject('')
    setMessage('')
  }

  const closeDrawer = function (): void {
    cleanUpAllFields()
    setDrawerStatus(NotificationDrawerStatusEnum.createNotification)
    setIsOpen(false)
  }

  const saveAsDraftAndCloseDrawer = function (): void {
    // send HTTP request to save
    closeDrawer()
  }

  const drawStatusProvider = function (drawerStatus: NotificationDrawerStatusEnum): React.JSX.Element {
    const commonProps = {
      notificationName,
      period,
      timeExecutionWindow,
      fallsOnBankHoliday,
    }
    const updateFieldsProps = {
      setNotificationName,
      setNotificationType,
      setSubject,
      setMessage,
      setPeriod,
      setTimeExecutionWindow,
      setFallsOnBankHoliday,
    }
    switch (drawerStatus) {
      case NotificationDrawerStatusEnum.createNotification:
        return (
          <CreateNotificationPage
            {...commonProps}
            {...updateFieldsProps}
            notificationType={notificationType}
            subject={subject}
            message={message}
            isRemoteFetch={true}
            updateDrawStatus={(status: NotificationDrawerStatusEnum) => setDrawerStatus(status)}
            closeDrawer={closeDrawer}
            saveAsDraftAndCloseDrawer={saveAsDraftAndCloseDrawer}
          />
        )
      case NotificationDrawerStatusEnum.preview:
        return (
          <NotificationDetailsPreview
            {...commonProps}
            closeDrawer={closeDrawer}
            saveAsDraftAndCloseDrawer={saveAsDraftAndCloseDrawer}
          />
        )
    }
  }

  return (
    <Drawer headerTitle={title} isOpen={isOpen} onClose={closeDrawer}>
      <div className="-mt-5 grid grid-cols-12 overflow-auto px-1" data-testid="create-notification-page-container">
        <div className="col-span-12 mb-4 text-lg font-bold text-text-primary">
          {formatMessage({id: 'NOTIFICATION_BUILDER.CREATE_NOTIFICATION_HEADER'})}
        </div>
        {drawStatusProvider(drawerStatus)}
      </div>
    </Drawer>
  )
}
