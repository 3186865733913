import NotificationDetails from '@/components/Drawer/NotificationDetails'
import NotificationSubject from '@/components/Drawer/NotificationSubject'
import {useIntl} from 'react-intl'

export default function EventNotificationDetails(): React.JSX.Element {
  const {formatMessage} = useIntl()
  // data parsing should depend on the notificationId API response data for future updates.
  const mockData = {
    name: 'New Mock Notification',
    description: 'Mock Description',
    country: 'Turkey',
    event: 'Event details',
    duration: {start: '10/10/2023', end: '10/10/2030'},
    timeExecutionWindow: {start: '9:00am', end: '10:00pm'},
    recurrency: 'Monday, Tuesday',
    fallsOnBankHoliday: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_SAME_DAY_OPTION',
  }

  return (
    <div className="-mt-1 overflow-auto px-1" data-testid="event-notification-details-container">
      <div className="mb-4 text-lg font-bold text-text-primary">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_HEADER'})}
      </div>
      <NotificationDetails
        notificationName={mockData?.name}
        period={mockData?.duration}
        timeExecutionWindow={mockData?.timeExecutionWindow}
        fallsOnBankHoliday={mockData?.fallsOnBankHoliday}
      />
      <div className="mt-4 text-text-primary">
        <div className="text-base font-bold">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_TITLE'})}
        </div>
        <NotificationSubject />
      </div>
    </div>
  )
}
