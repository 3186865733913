import {getParamByValue} from '@/utils/consts'
import {updateNodeParamsSelectors, updateReEntryDays} from '@ReduxActions'
import createEngine, {
  BaseEvent,
  DiagramEngine,
  DiagramModel,
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from '@projectstorm/react-diagrams'
import _ from 'lodash'
import {nodesConfig} from '../../../config/canvas'
import {STEP_CONFIG} from '../../../config/constants'
import {store} from '../../../store'
import {NodeTypeEnum} from '../../../types/NodeTypeEnum'
import {EntryCondition} from '../../../types/Steps'
import {ParamsSelector} from '../../../types/paramFilters/paramFilters'
import {logEvent} from '../../../utils/logger'
import {CustomLinkFactory} from '../customLink/CustomLinkFactory'
import {CustomLinkModel} from '../customLink/CustomLinkModel'
import {CustomNodeModel} from '../customNodes/CustomNodeModel'
import {CustomPortFactory} from '../customNodes/CustomPortFactory'
import {EntryConditionNodeFactory} from '../customNodes/EntryConditionNode/EntryConditionNodeFactory'
import {EntryConditionNodeModel} from '../customNodes/EntryConditionNode/EntryConditionNodeModel'
import {EntryConditionPortModel} from '../customNodes/EntryConditionNode/EntryConditionPortModel'
import {RequiresInterventionNodeFactory} from '../customNodes/RequiresIntervention/RequiresInterventionNodeFactory'
import {RequiresInterventionNodeModel} from '../customNodes/RequiresIntervention/RequiresInterventionNodeModel'
import {RequiresInterventionPortModel} from '../customNodes/RequiresIntervention/RequiresInterventionPortModel'
import {SendPushNodeFactory} from '../customNodes/SendPush/SendPushNodeFactory'
import {SendPushNodeModel} from '../customNodes/SendPush/SendPushNodeModel'
import {SendPushPortModel} from '../customNodes/SendPush/SendPushPortModel'
import {SendSMSNodeFactory} from '../customNodes/SendSMSNode/SendSMSNodeFactory'
import {SendSMSNodeModel} from '../customNodes/SendSMSNode/SendSMSNodeModel'
import {SendSMSPortModel} from '../customNodes/SendSMSNode/SendSMSPortModel'
import {SendWhatsAppNodeFactory} from '../customNodes/SendWhatsAppNode/SendWhatsAppNodeFactory'
import {SendWhatsAppNodeModel} from '../customNodes/SendWhatsAppNode/SendWhatsAppNodeModel'
import {SendWhatsAppPortModel} from '../customNodes/SendWhatsAppNode/SendWhatsAppPortModel'
import {VariantNodeFactory} from '../customNodes/VariantNode/VariantNodeFactory'
import {VariantNodeModel} from '../customNodes/VariantNode/VariantNodeModel'
import {VariantPortModel} from '../customNodes/VariantNode/VariantPortModel'
import {WaitNodeFactory} from '../customNodes/WaitNode/WaitNodeFactory'
import {WaitNodeModel} from '../customNodes/WaitNode/WaitNodeModel'
import {WaitPortModel} from '../customNodes/WaitNode/WaitPortModel'
import {DefaultState} from './DefaultState'

const nodesAndPortsFactory = [
  {
    nodeFactory: new VariantNodeFactory(),
    portFactory: new CustomPortFactory(NodeTypeEnum.variant, () => new VariantPortModel(PortModelAlignment.BOTTOM)),
  },
  {
    nodeFactory: new EntryConditionNodeFactory(),
    portFactory: new CustomPortFactory(
      NodeTypeEnum.entryCondition,
      () => new EntryConditionPortModel(PortModelAlignment.BOTTOM)
    ),
  },
  {
    nodeFactory: new SendPushNodeFactory(),
    portFactory: new CustomPortFactory(NodeTypeEnum.sendPush, () => new SendPushPortModel(PortModelAlignment.BOTTOM)),
  },
  {
    nodeFactory: new SendSMSNodeFactory(),
    portFactory: new CustomPortFactory(NodeTypeEnum.sendSMS, () => new SendSMSPortModel(PortModelAlignment.BOTTOM)),
  },
  {
    nodeFactory: new SendWhatsAppNodeFactory(),
    portFactory: new CustomPortFactory(
      NodeTypeEnum.sendWhatsApp,
      () => new SendWhatsAppPortModel(PortModelAlignment.BOTTOM)
    ),
  },
  {
    nodeFactory: new WaitNodeFactory(),
    portFactory: new CustomPortFactory(NodeTypeEnum.wait, () => new WaitPortModel(PortModelAlignment.BOTTOM)),
  },
  {
    nodeFactory: new RequiresInterventionNodeFactory(),
    portFactory: new CustomPortFactory(
      NodeTypeEnum.requiresIntervention,
      () => new RequiresInterventionPortModel(PortModelAlignment.BOTTOM)
    ),
  },
]

export class Application {
  protected activeModel: DiagramModel
  protected diagramEngine: DiagramEngine

  private itv?: NodeJS.Timeout

  private debouncedDelayToAutoZooming = 1000 / 60
  private delayToRetryAutoZooming = 100

  private updateNodesPositionFrequency = 1000 / 60
  private nodesSizes: string[] = []

  constructor() {
    this.diagramEngine = this.newEngine()
    this.activeModel = new DiagramModel()
    this.newModel()
  }

  public newEngine() {
    //1) setup the diagram engine
    const engine = createEngine()

    engine.getLinkFactories().registerFactory(new CustomLinkFactory())
    // register some other factories as well
    nodesAndPortsFactory.forEach(data => {
      engine.getPortFactories().registerFactory(data.portFactory)
      engine.getNodeFactories().registerFactory(data.nodeFactory)
    })

    // Custom state open for engine modifications
    engine.getStateMachine().pushState(new DefaultState())

    return engine
  }

  private newModel() {
    this.diagramEngine.setModel(this.activeModel)

    this.activeModel.registerListener({
      eventDidFire: (diagramEvent: BaseEvent) => {
        const diagramModel = this.activeModel
        if (!diagramModel) return

        logEvent.debug('app:event DidFire', diagramEvent)

        const models = diagramModel.getModels()
        models.forEach(model => {
          model.registerListener({
            eventDidFire: (modelEvent: BaseEvent & {function?: string}) => {
              logEvent.debug('app:modelEvent didFire', modelEvent)

              if (modelEvent.function === 'entityRemoved') {
                canvasApp.getDiagramEngine().repaintCanvas()
              }
            },
          })
        })
      },
    })

    this.diagramEngine.registerListener({
      eventDidFire: (engineEvent: BaseEvent & {function?: string}) => {
        logEvent.debug('app:diagramEngine', engineEvent)

        if (engineEvent.function === 'canvasReady') {
          canvasApp.initCanvasResizeListeners()
        }
      },
    })

    this.listenForSizeUpdates()
  }

  public initializeEntryCondition(entryConditionData?: EntryCondition) {
    const node = new this.nodeFactory[NodeTypeEnum.entryCondition](entryConditionData?.id)
    const {x, y} = STEP_CONFIG.DEFAULT_ENTRY_CONDITION_POSITION
    node.setPosition(x, y)

    this.activeModel.addAll(node)
    this.setupEntryConditionData(entryConditionData)
  }

  private setupEntryConditionData(entryConditionData?: EntryCondition) {
    if (!entryConditionData) return
    const {config, id, reentryDays} = entryConditionData

    store.dispatch(
      updateReEntryDays({
        id,
        reentryDays,
      })
    )

    const paramsSelectors: Array<ParamsSelector> = []
    config.forEach((trait, idx) => {
      paramsSelectors.push({
        conditional: 'AND',
        id: idx,
        selectedParam: trait.field,
        selectedParamType: getParamByValue(trait.field).type,
        selectedParamData: {
          operator: trait.operator,
          value: trait.value === null ? trait.value : String(trait.value),
        },
      } as ParamsSelector)
    })

    if (config.length) {
      store.dispatch(
        updateNodeParamsSelectors({
          id,
          paramsSelectors,
        })
      )
    }

    canvasApp.initCanvasResizeListeners()
  }

  private initCanvasResizeListeners() {
    window.removeEventListener('resize', canvasApp.zoomToFitSteps)
    window.addEventListener('resize', canvasApp.zoomToFitSteps)
    canvasApp.zoomToFitSteps()
  }

  public zoomToFitSteps() {
    clearTimeout(canvasApp.itv)
    canvasApp.itv = setTimeout(() => canvasApp.resetZoomToFitSteps(), canvasApp.debouncedDelayToAutoZooming)
  }

  private resetZoomToFitSteps(retry = 5) {
    const diagramModel = canvasApp.getActiveDiagram()
    const hasCanvas = !!canvasApp.getDiagramEngine().getCanvas()

    if (!hasCanvas || !diagramModel) {
      if (retry > 0) {
        setTimeout(() => canvasApp.resetZoomToFitSteps(retry - 1), canvasApp.delayToRetryAutoZooming)
      }
      return
    }

    try {
      canvasApp.getDiagramEngine().zoomToFitNodes({margin: 20, maxZoom: 1})
      diagramModel.setOffsetY(diagramModel.getZoomLevel() >= 100 ? 0 : diagramModel.getOffsetY() - 5)
    } catch (error) {
      console.error('Error while zooming to fit steps', error)
    }
  }

  // Add a new node after the last added node
  public addLinkedStep(type: NodeTypeEnum, id?: string) {
    if (!nodesConfig.filter(nodeConfig => nodeConfig.type === type)[0]?.isEnabled()) {
      return
    }

    const sourceStep = this.getNodes().last as CustomNodeModel
    const targetStep = new this.nodeFactory[type](id)

    this.setNodesPosition({sourceStep, targetStep: targetStep})
    this.setLinkNodes({sourceStep, targetStep: targetStep})
    canvasApp.getDiagramEngine().getModel().addNode(targetStep)
  }

  // Add a new node after a specified node
  public insertLinkedStep(
    type: NodeTypeEnum,
    sourceId: string,
    id?: string,
    portDirection: PortModelAlignment = PortModelAlignment.BOTTOM,
    nodeModel?: CustomNodeModel
  ) {
    const sourceStep = this.getNodeById(sourceId) as CustomNodeModel

    if (sourceStep.getType() === NodeTypeEnum.variant) {
      console.error('TODO: Adding a step after a Variant step is not implemented!')
      return
    }

    const sourceLinks = this.getLinkInfo(sourceStep, portDirection)
    const sourceLinkTargetId = sourceLinks?.targetLinkId

    // Remove current link from source Step
    sourceLinks.link?.remove()

    // Create a new Step whith selected type
    const newStep = nodeModel ?? new this.nodeFactory[type](id)

    // Set position of the new Step
    this.setNodesPosition({sourceStep, targetStep: newStep})
    canvasApp.getDiagramEngine().getModel().addNode(newStep)

    // Link the new Step to the source Step
    const linkSourceToNew = new CustomLinkModel()
    linkSourceToNew.setSourcePort(sourceStep.getPort(portDirection))
    linkSourceToNew.setTargetPort(newStep.getPort(PortModelAlignment.TOP))
    canvasApp.getDiagramEngine().getModel().addLink(linkSourceToNew)

    if (sourceLinkTargetId) {
      // Set position of the previous target from the old source Step
      const previousSourceTargetStep = this.getNodeById(sourceLinkTargetId) as CustomNodeModel

      // Link the new Step to the previous source's target Step
      const linkNewToPreviousTarget = new CustomLinkModel()
      linkNewToPreviousTarget.setSourcePort(newStep.getPort(portDirection))
      linkNewToPreviousTarget.setTargetPort(previousSourceTargetStep.getPort(PortModelAlignment.TOP))
      canvasApp.getDiagramEngine().getModel().addLink(linkNewToPreviousTarget)

      // Re-arrange all the next Steps
      this.reArrangeNextStepsPositions(previousSourceTargetStep, portDirection)
    }
  }

  private listenForSizeUpdates() {
    setTimeout(() => this.listenForSizeUpdates(), this.updateNodesPositionFrequency)

    const diagramModel = this.getActiveDiagram()
    const hasCanvas = !!this.getDiagramEngine().getCanvas()
    if (!hasCanvas || !diagramModel) {
      return
    }

    canvasApp.getDiagramEngine().repaintCanvas()

    const newNodeSizes = diagramModel.getNodes().map(node => {
      return JSON.stringify(node.getBoundingBox())
    })

    if (_.isEqual(newNodeSizes, this.nodesSizes)) {
      return
    }

    this.nodesSizes = newNodeSizes
    this.updateAllNodesPositions()
  }

  private updateAllNodesPositions() {
    const diagramModel = canvasApp.getActiveDiagram()
    const firstStep = diagramModel.getNodes()[0] as CustomNodeModel
    canvasApp.reArrangeNextStepsPositions(firstStep, PortModelAlignment.BOTTOM)
    canvasApp.getDiagramEngine().repaintCanvas()
  }

  private reArrangeNextStepsPositions(currentStep: CustomNodeModel, portDirection: PortModelAlignment) {
    const currentStepLinks = this.getLinkInfo(currentStep, portDirection)
    const currentStepTargetLinkId = currentStepLinks?.targetLinkId
    const nextStep = this.getNodeById(currentStepTargetLinkId) as CustomNodeModel
    if (!currentStepTargetLinkId || !nextStep) {
      canvasApp.getDiagramEngine().repaintCanvas()
      return
    }

    this.setNodesPosition({sourceStep: currentStep, targetStep: nextStep})

    this.reArrangeNextStepsPositions(nextStep, portDirection)
  }

  private setNodesPosition({sourceStep, targetStep}: {sourceStep: CustomNodeModel; targetStep: CustomNodeModel}) {
    const sourceSize = sourceStep.getBoundingBox()
    const targetSize = targetStep.getBoundingBox()

    const sourceStaticSize = sourceStep.getOptions().extras.nodeSize
    const targetStaticSize = targetStep.getOptions().extras.nodeSize

    const pX = !targetSize.getWidth()
      ? sourceSize.getBottomLeft().x + (sourceStaticSize - targetStaticSize) / 2
      : sourceSize.getBottomMiddle().x - targetSize.getWidth() / 2

    const gapY = STEP_CONFIG.DEFAULT_NODE_Y_DISTANCE

    const targetPosition = {
      x: pX,
      y: sourceSize.getBottomMiddle().y + gapY,
    }

    targetStep.setPosition(targetPosition.x, targetPosition.y)
  }

  private setLinkNodes({sourceStep, targetStep}: {sourceStep: CustomNodeModel; targetStep: CustomNodeModel}) {
    const link = new CustomLinkModel()
    link.setSourcePort(sourceStep.getPort(PortModelAlignment.BOTTOM))
    link.setTargetPort(targetStep.getPort(PortModelAlignment.TOP))
    canvasApp.getDiagramEngine().getModel().addLink(link)
  }

  public reset() {
    const diagramModel = this.activeModel
    diagramModel.clearSelection()
    diagramModel.getNodes().forEach(node => (node as CustomNodeModel).removeForce())
    diagramModel.getLinks().forEach(link => link.remove())
    diagramModel.getModels().forEach(model => model.remove())
    diagramModel.getLayers().forEach(layer => layer.allowRepaint(true))

    this.resetCanvas()
  }

  private resetCanvas() {
    const diagramModel = this.activeModel
    const {x, y} = STEP_CONFIG.DEFAULT_OFFSET_POSITION
    diagramModel.setOffset(x, y)
    diagramModel.setZoomLevel(STEP_CONFIG.DEFAULT_ZOOM_LEVEL)
    canvasApp.getDiagramEngine().repaintCanvas()
  }

  public getActiveDiagram(): DiagramModel {
    return this.activeModel
  }

  public getDiagramEngine(): DiagramEngine {
    return this.diagramEngine
  }

  public getNodeById = (id: string) => {
    const {nodes} = this.getNodes()
    return nodes.find(node => node.getID() === id)
  }

  public getNodes() {
    const nodes = canvasApp.getActiveDiagram().getNodes()
    const first = nodes[0]
    const last = nodes[nodes.length - 1]

    return {
      nodes,
      first,
      last,
    }
  }

  public getLinkInfo(node: NodeModel<NodeModelGenerics>, portDirection: PortModelAlignment) {
    const linksFromPort = Object.keys(node?.getPort(portDirection)?.getLinks() ?? {})
    const link = canvasApp.getActiveDiagram().getLink(linksFromPort[0])
    const sourceLinkId = link?.getSourcePort().getNode().getID()
    const targetLinkId = link?.getTargetPort().getNode().getID()

    return {
      linksFromPort,
      link,
      sourceLinkId,
      targetLinkId,
    }
  }

  public nodeFactory: {
    [key: string]: new (id?: string) => CustomNodeModel
  } = {
    [NodeTypeEnum.entryCondition]: EntryConditionNodeModel,
    [NodeTypeEnum.requiresIntervention]: RequiresInterventionNodeModel,
    [NodeTypeEnum.sendPush]: SendPushNodeModel,
    [NodeTypeEnum.sendSMS]: SendSMSNodeModel,
    [NodeTypeEnum.sendWhatsApp]: SendWhatsAppNodeModel,
    [NodeTypeEnum.variant]: VariantNodeModel,
    [NodeTypeEnum.wait]: WaitNodeModel,
  }
}

export const canvasApp = new Application()
