import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {ComponentPropsWithoutRef, useEffect, useState} from 'react'
import {CustomColumnMeta} from '../../components/JourneysListTable/components/columns'
import {DataTablePagination} from './DataTablePagination'

type DataTableProps<TData, TValue> = ComponentPropsWithoutRef<'table'> & {
  columns: ColumnDef<TData, TValue>[]
  data?: TData[]
  pagination: PaginationState
  pageCount: number
  onPaginationDidChange: (state: PaginationState) => void
  sortingColumn: SortingState
  onSortingDidChange: (state: SortingState) => void
}

export function DataTable<TData, TValue>({
  columns,
  data = [],
  pagination,
  pageCount,
  onPaginationDidChange,
  onSortingDidChange,
  sortingColumn,
  ...props
}: DataTableProps<TData, TValue>) {
  const [{pageIndex, pageSize}, setPagination] = useState(pagination)
  const [sorting, setSorting] = useState(sortingColumn)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility: {
        country: !isFeatureEnabled(FeatureToggle.userRole),
        description: !isFeatureEnabled(FeatureToggle.journeyType),
        type: isFeatureEnabled(FeatureToggle.journeyType),
      },
    },
    onPaginationChange: setPagination,
    manualSorting: true,
    enableSorting: pageCount > 0,
    onSortingChange: setSorting,
  })

  useEffect(() => {
    if (pageIndex === pagination.pageIndex) {
      return
    }
    onPaginationDidChange({pageIndex, pageSize})
  }, [onPaginationDidChange, pageIndex, pageSize, pagination.pageIndex])

  useEffect(() => {
    if (JSON.stringify(sorting) === JSON.stringify(sortingColumn)) {
      return
    }
    onSortingDidChange(sorting)
  }, [onSortingDidChange, sorting, sortingColumn])

  const headerGroups = table.getHeaderGroups()

  const renderTableHeaderColumns = headerGroups.map(({id, headers}) => {
    return (
      <tr key={id}>
        {headers.map(({id: key, isPlaceholder, column, getContext}) => {
          const meta = column.columnDef.meta as CustomColumnMeta
          const styles = meta?.styles

          return (
            <th key={key} className="border-b border-blue-500 uppercase" style={styles}>
              {isPlaceholder ? null : flexRender(column.columnDef.header, getContext())}
            </th>
          )
        })}
      </tr>
    )
  })

  const {rows} = table.getRowModel()
  const isEmpty = !rows?.length

  const renderTableRowColumns = rows.map(({id, ...row}) => {
    const visibleCells = row.getVisibleCells()
    return (
      <tr key={id} className="border-b border-blue-500 bg-blue-50" data-cy={`item-row`}>
        {visibleCells.map(cell => (
          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
        ))}
      </tr>
    )
  })

  return (
    <>
      <table className="mb-4 table w-full" {...props}>
        <thead className="text-left text-xs font-bold text-text-secondary">{renderTableHeaderColumns}</thead>
        <tbody className="bg-background text-left text-sm text-text-secondary">
          {isEmpty ? <EmptyResults size={columns.length} /> : renderTableRowColumns}
        </tbody>
      </table>

      <DataTablePagination
        currentPage={table.getState().pagination.pageIndex + 1}
        totalPages={table.getPageCount()}
        {...table}
      />
    </>
  )
}

const EmptyResults = ({size}: {size: number}) => {
  return (
    <tr>
      <td colSpan={size} className="py-24 text-center text-lg" data-testid="no-results">
        No journeys found!
      </td>
    </tr>
  )
}
