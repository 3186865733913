import {cn} from '@/helpers/shadcn/utils'
import {ComponentPropsWithoutRef, PropsWithChildren} from 'react'

type PageHeaderProps = PropsWithChildren<ComponentPropsWithoutRef<'div'>> & {
  title: string
}

export const PageHeader = ({title, className, children}: PageHeaderProps) => {
  return (
    <div className={cn('flex w-full items-center justify-between gap-4 font-bold text-text-primary', className)}>
      <h1 className="flex-wrap text-2xl">{title}</h1>
      {children}
    </div>
  )
}
