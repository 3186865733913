import NotificationDrawer from '@/components/Drawer/NotificationDrawer'
import {useState} from 'react'
import NotificationList from './NotificationList'

export default function TriggerBasedNotification(): React.JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <div className="mt-5 grid grid-cols-12 gap-4" data-testid="trigger-based-notification-container">
      <p className="col-span-4 text-3xl font-bold">Welcome Back, User!</p>
      <div className="col-span-12">
        <NotificationList setIsOpen={setIsOpen} />
      </div>
      <NotificationDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}
