import {Icon} from 'pepsico-ds'

type InfoOrErrorProps = {
  id?: string
  info?: string
  error?: string
}

export const InfoOrError = ({info, error, id}: InfoOrErrorProps) => {
  const hasInfoOrError = info || error

  if (!hasInfoOrError) {
    return null
  }

  return (
    <div className="flex items-start justify-between">
      {info && !error && (
        <span className="flex items-start gap-1" data-testid={`${id}-info-msg`}>
          <Icon icon="info" size="small" variant="filled" className="!text-xs text-text-primary" />
          <p className={'text-xs text-text-secondary'}>{info}</p>
        </span>
      )}
      {error && (
        <span className="flex items-start gap-1" data-testid={`${id}-error-msg`} data-cy={`${id}-error-msg`}>
          <Icon icon="error_outline" size="small" className="!text-xs text-support-error-primary" />
          <p className={'text-xs text-support-error-primary'}>{error}</p>
        </span>
      )}
    </div>
  )
}
